.cover-page {
  height: 100vh;
  /* background: pink; */
}

.navDark {
  background: rgba(0, 0, 0, 0.6);
}

.nav-link {
  color: rgb(216, 216, 216) !important;
}

.nav-link.active,
.nav-link:hover {
  color: #fdd86a !important;
}

.subComponent {
  padding: 50px 0px;
  
}

.subComponent-lg {
  padding: 80px 0px;
}

#hero {
  background: url("https://d29f0tpif9qm0q.cloudfront.net/images/miscellaneous/bg.jpg") center center;
  background-size: cover;
  background-attachment: fixed;
  color: white;
}

#hero h1 {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-size: 60px;
}

#packageBody {
  /* background-color: rgb(245, 245, 245); */
  background-color: #f5f5f5;
}
.wrapped-page {
  width: 100%;
  height: 100vh;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}

.item-center {
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
/* remaining */
.img-carousel {
  width: 40%;
  height: 40%;
  object-fit: fill;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
/* Remaining */
.carousel-caption {
  height: 50%;
}
/* Remaining */
.carousel-caption h3 {
  font-size: 5em;
  font-weight: 700;
  text-shadow: 3px 3px 10px black;
}
/* Remaining */
.carousel-caption p {
  color: rgb(255, 239, 16);
  text-shadow: 2px 2px 5px black;
}

.card {
  margin: 10px 0px;
  padding: 5%;
}

@media (min-width: 284px) {
  .card-columns {
    margin: 0px 20px;
  }

  .tour-msg {
    margin: 0px 10px;
  }
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 2 !important;
    -moz-column-count: 2 !important;
    column-count: 2 !important;
  }

  .tour-msg {
    margin: 0px 20px;
  }
}

@media (min-width: 986px) {
  .card-columns {
    -webkit-column-count: 3 !important;
    -moz-column-count: 3 !important;
    column-count: 3 !important;
  }

  .tour-msg {
    margin: 0px 50px;
  }
}

.packageBody .badge {
  margin: 0px 5px;
  cursor: pointer;
  text-transform: capitalize;
}

.tour-cover {
  width: 90%;
  padding: 20px 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  margin: 50px auto;

}

.tour-cover img {
  width: 100%;
}

.tour-gallery img {
  width: 50%;
  height: 100px;
  object-fit: cover;
  padding: 5px;
}

.tour-msg input,
.tour-msg textarea {
  width: 100%;
  margin: 5px;
  border: 1px solid #6c757d;
  padding: 10px;
  border-radius: 5px;
  background: none;
}

.msg input,
.msg textarea {
  width: 100%;
  margin: 5px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
}

.msg .btn {
  margin: 5px;
}

.reviews {
  background: #f1f1f1;
  padding: 40px 0px 80px 0px;
  margin: 0px;
}

#aboutBody {
  background: #f1f1f1;
  color: #2b2b2b;
}

.svg-group p {
  margin: 15px 0px;
}

.svg-card {
  background: rgba(0, 0, 0, 0.1);
  color: #2b2b2b;
  padding: 30px;
  margin: 10px;
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
}

.svg-card:hover {
  border-radius: 50%;
}

.wrapped-services {
  background: url("https://d29f0tpif9qm0q.cloudfront.net/images/miscellaneous/bg2.jpg") center center;
  background-size: cover;
  background-attachment: fixed;
}

#servicesBody {
  background: rgba(0, 0, 0, 0.7);
}

#contactBody {
  background: #6d613c;
  color: rgba(0, 0, 0, 0.7);
}

#footerBody {
  background: #383322;
  color: rgba(0, 0, 0, 0.7);
}

#footerBody a {
  color: rgba(0, 0, 0, 0.6);
}

#footerBody a:hover {
  color: rgba(255, 255, 255, 0.6);
}

.svg-card-2 {
  color: #ffffff;
  padding: 30px;
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
}

.svg-card-2:hover {
  border-style: solid;
  border-width: 2px;
  border-color: #e0e0e0;
  background: rgba(255, 255, 255, 0.3);
}

.svg-card-3 {
  padding: 30px;
  margin: 10px;
  color: rgba(255, 255, 255, 0.7);
}

.headerTitle p {
  color: rgba(0, 0, 0, 0.5);
}

* {
  transition-duration: 0.3s;
}

#home {
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

.scroll-indicator span {
  display: block;
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
  animation: scrollAnimation 1s infinite alternate;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(5px);
    opacity: 1;
  }
}
.scroll-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Footer */
footer {
  background-color: #383322;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 20px 0;
}

footer a {
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.3s;
}

footer a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.book-now {
  background-color: #f8f8f8;
  padding: 50px 0;
}

.book-now-content {
  text-align: center;
}

.book-now-content h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.book-now-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.book-now-content button {
  padding: 12px 30px;
  font-size: 16px;
}
.whatsapp-icon {
  font-size: 35px;
  color: green;
  padding-left: 10px;
}
.whatsapp-button {
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.whatsapp-button:hover {
  background-color: #128c7e;
  transform: scale(1.1);
}